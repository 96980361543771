function marker(key, context, comment) {
  return key;
}

/*
 * Public API Surface of ngx-translate-extract-marker
 */

/**
 * Generated bundle index. Do not edit.
 */

export { marker };
